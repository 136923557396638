import { ApiEmployeeTrackingReports } from '@@types/apiLogicTypes'
import { Loader } from '@components/ui/Loader/Loader'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { createFormData } from '@utils/createDataForm'
import times from '@utils/times'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { useContext, useEffect, useState } from 'react'
import { buttonsTemplate, datePicketBodyTemplate, employeeBodyTemplate } from '../shared/TableTemplates'

export const WorkTimeTrack = observer(() => {
  const logic = useLogic()
  const context = useContext(ToastContext)

  const {
    users: {
      employeeTrackingReports,
      employees,
      deleteEmployeeTrackingReport,
      addEmptyTrackingReport: addEmptyReport,
      editEmployeeTrackingReport,
    },
    auth: { selectedPark },
  } = useStore()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!selectedPark) return
    Promise.all([logic.getEmployeeTracking(selectedPark.id, 1), logic.loadEmployees(), logic.loadRoles()]).then(() =>
      setLoading(false)
    )
  }, [selectedPark])

  const addRow = () => {
    addEmptyReport(selectedPark!.id)
  }

  const deleteReport = async (e: ApiEmployeeTrackingReports, rowIndex?: number) => {
    if (!e.id) {
      deleteEmployeeTrackingReport(e.id, rowIndex)
      return
    }

    const { status, errors } = await logic.deleteEmployeeTrackingById(e.id)
    if (status) deleteEmployeeTrackingReport(e.id)
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  const addReport = async (e: ApiEmployeeTrackingReports, rowIndex: number) => {
    if (!e.user_id || !e.date || !e.from || !e.to) {
      context?.toastShowHandler({ status: false, errors: 'Заполните все поля' })
      return
    }

    const formData = createFormData({
      park_id: selectedPark!.id,
      user_id: e.user_id,
      type: 1,
      date: e.date,
      from: e.from,
      to: e.to,
      format: 1,
      role: e.role,
      working_rate: e.user?.working_rate ?? 0,
    })
    const { status, errors } = await logic.createEmployeeTrackingById(formData, rowIndex)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  const editReport = async (id: number, query: string) => {
    const { status, errors } = await logic.editEmployeeTrackingById(id, query)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  if (loading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }

  return (
    <Layout className='!p-0'>
      <section className='flex h-full w-full flex-col gap-8 overflow-auto bg-white px-4 py-5'>
        <header className='flex flex-row items-center gap-6'>
          <UiText className='text-lg font-bold'>Учёт времени работы</UiText>
          <UiButton onClick={addRow}>
            <i className='pi pi-plus'></i>
          </UiButton>
        </header>

        <DataTable
          value={employeeTrackingReports}
          showGridlines
          size={'small'}
          filterLocale='ru'
          className={'ui-paginator-table h-fit bg-white text-base'}
          style={{ fontSize: '12px' }}
          removableSort
          emptyMessage='Данные не найдены'
          scrollable
          scrollHeight='flex'
          paginator
          rows={15}
          columnResizeMode='expand'
          sortField='date'
        >
          <Column
            filterField='created_at'
            header='Дата'
            dataType='date'
            body={(e, options) => datePicketBodyTemplate(e, editReport, editEmployeeTrackingReport, options.rowIndex)}
          />
          <Column
            field='time'
            header='Время'
            body={(e, options) => timePicker(e, editReport, editEmployeeTrackingReport, options.rowIndex)}
          />
          <Column
            field='employee'
            header='Сотрудник'
            body={(e: ApiEmployeeTrackingReports, options) =>
              employeeBodyTemplate(e, employees, editReport, editEmployeeTrackingReport, options.rowIndex)
            }
          />
          <Column field='children' header='Должность' body={(e) => roleBodyTemplate(e)} />
          <Column
            field='delete'
            body={(e, options) =>
              buttonsTemplate(
                () => deleteReport(e, options.rowIndex),
                !e.id ? () => addReport(e, options.rowIndex) : undefined
              )
            }
          />
        </DataTable>
      </section>
    </Layout>
  )
})

const roleBodyTemplate = (report: ApiEmployeeTrackingReports) => {
  return (
    <input
      className='text-field h-[36px] rounded-[3px] border-[1px] border-solid border-green bg-white px-[8px] py-[10px] text-grey focus:outline-none'
      placeholder='Должность'
      value={report.role ?? ''}
      disabled={true}
    ></input>
  )
}

const timePicker = (
  report: ApiEmployeeTrackingReports,
  editCallback: (id: number, query: string) => Promise<void>,
  editLocalStateCallback: (report: ApiEmployeeTrackingReports, rowIndex?: number) => void,
  rowIndex: number
) => {
  const startTime = new Date(String(report.from)).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })

  const endTime = new Date(String(report.to)).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })

  const onChangeStartTimeHandler = async (e: DropdownChangeEvent) => {
    const from = new Date(report.date).toLocaleDateString('en-CA') + ' ' + e.value + ':00'

    if (!report.id) {
      editLocalStateCallback({ ...report, from: from }, rowIndex)
      return
    }

    const query = `from=${from}`
    await editCallback(report.id, query)
  }

  const onChangeEndTimeHandler = async (e: DropdownChangeEvent) => {
    const to = new Date(report.date).toLocaleDateString('en-CA') + ' ' + e.value + ':00'

    if (!report.id) {
      editLocalStateCallback({ ...report, to: to }, rowIndex)
      return
    }

    const query = `to=${to}`
    await editCallback(report.id, query)
  }

  return (
    <div className='flex items-center gap-1'>
      <Dropdown
        value={startTime}
        className='worker timeInput h-[36px] border-green text-red'
        panelClassName='worker-panel'
        color='green'
        options={times}
        onChange={onChangeStartTimeHandler}
      />
      <p className='m-0'>—</p>
      <Dropdown
        value={endTime}
        className={`worker timeInput h-[36px] border-green`}
        panelClassName='worker-panel'
        color='green'
        options={times.filter((e) => e > startTime)}
        onChange={onChangeEndTimeHandler}
      />
    </div>
  )
}
