import { ApiNewsLetter } from '@@types/apiLogicTypes'
import { LoadState } from '@@types/loadState'
import { buttonBodyTemplate, nameBodyTemplateWithIcon } from '@components/ui/ColumnContents/ColumnContents'
import { SettingsHeaderBtn } from '@components/ui/SettingsHeaderBtn/SettingsHeaderBtn'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ArchiveContext } from '@context/archiveContext'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { onAdd } from '@utils/tableUtils'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { FC, useContext, useEffect } from 'react'

interface INewsLetter {
  isAdmin?: boolean
  inArchive?: boolean
}

export const newsletterTriggers = [
  { name: 'Первый визит', id: 1 },
  { name: 'Не ходили более Х недель', id: 8 },
  { name: 'Проведение ДР', id: 9 },
  { name: 'Покупка билета', id: 4 },
  { name: 'День рождения через Х дней', id: 7 },
  { name: 'По дате и времени', id: 10 },
  { name: 'Заявка с сайта', id: 11 },
  { name: 'Спустя Х часов после визита', id: 12 },
]

export const NewsLetters: FC<INewsLetter> = observer(({ isAdmin, inArchive = false }) => {
  const logic = useLogic()
  const context = useContext(ToastContext)

  const {
    advertising: { loading, newsletter },
    auth: { selectedPark },
  } = useStore()

  useEffect(() => {
    if (!selectedPark) return
    isAdmin ? logic.loadAdminNewsletter() : logic.loadNewsletter(selectedPark.id)
  }, [selectedPark])

  const onDelete = async (id: number) => {
    const { status, errors } = await logic.deleteNewsletter(id)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  const onArchive = async (item: ApiNewsLetter) => {
    const { status, errors } = await logic.updateNewsletter(`?deleted=${Number(!Boolean(item.deleted))}`, item.id)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  const toArchive = () => {
    navigateTo(AppRoutesPaths.AdvertisingNewsletterArchive)
  }

  return (
    <Layout title='Рассылки' scrollable>
      <ArchiveContext.Provider value={{ inArchive, onArchive: toArchive }}>
        <SettingsHeaderBtn
          textAdd={'Рассылку'}
          onClickAdd={() =>
            onAdd(isAdmin ? AppRoutesPaths.AdminPanelAdNewsletter : AppRoutesPaths.AdvertisingAddNewsletter)
          }
          textArchive={'Рассылки'}
          isAdmin={isAdmin}
        />
        <DataTable
          value={isAdmin ? newsletter : newsletter.filter((e) => e.deleted === (inArchive ? 1 : 0))}
          showGridlines
          className='bg-white text-base'
          style={{ fontSize: '12px' }}
          removableSort
          sortOrder={1}
          emptyMessage='Нет данных'
          loading={loading === LoadState.Loading}
        >
          <Column
            field='name'
            header='Название'
            sortable
            body={(item) =>
              nameBodyTemplateWithIcon(
                item,
                isAdmin ? AppRoutesPaths.AdminPanelEditNewsletter : AppRoutesPaths.AdvertisingEditNewsletter
              )
            }
          />
          <Column field='trigger' header='Условие' sortable body={(e) => triggerBodyTemplate(e.trigger_type)} />
          <Column field='activations' header='Активаций' sortable />
          <Column
            field=''
            header=''
            body={(item) =>
              buttonBodyTemplate({
                item: item,
                path: isAdmin ? AppRoutesPaths.AdminPanelEditNewsletter : AppRoutesPaths.AdvertisingEditNewsletter,
                onArchive: !isAdmin ? () => onArchive(item) : undefined,
                onDelete: !isAdmin ? () => onDelete(item.id) : undefined,
              })
            }
          />
        </DataTable>
      </ArchiveContext.Provider>
    </Layout>
  )
})

const triggerBodyTemplate = (id: number) => {
  return <UiText>{newsletterTriggers.find((e) => e.id === id)?.name}</UiText>
}
