import { ApiEmployeeTrackingReports } from '@@types/apiLogicTypes'
import { Loader } from '@components/ui/Loader/Loader'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { createFormData } from '@utils/createDataForm'
import { observer } from 'mobx-react-lite'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { buttonsTemplate, datePicketBodyTemplate, employeeBodyTemplate } from '../shared/TableTemplates'

export const AdditionalTrack = observer(() => {
  const {
    users: {
      additionalTrackingReports,
      employees,
      deleteAdditionalTrackingReport,
      addEmptyAdditionalReport,
      editAdditionalTrackingReport,
    },
    auth: { selectedPark },
  } = useStore()

  const logic = useLogic()
  const context = useContext(ToastContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!selectedPark) return
    Promise.all([logic.getEmployeeTracking(selectedPark.id, 2), logic.loadEmployees()]).then(() => setLoading(false))
  }, [selectedPark])

  const deleteReport = async (e: ApiEmployeeTrackingReports, rowIndex?: number) => {
    if (!e.id) {
      deleteAdditionalTrackingReport(e.id, rowIndex)
      return
    }

    const { status, errors } = await logic.deleteEmployeeTrackingById(e.id)
    if (status) deleteAdditionalTrackingReport(e.id)
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  const addRow = () => {
    addEmptyAdditionalReport(selectedPark!.id)
  }

  const addReport = async (e: ApiEmployeeTrackingReports, rowIndex: number) => {
    if (!e.user_id || !e.date || !e.format || !e.sum) {
      context?.toastShowHandler({ status: false, errors: 'Заполните все поля' })
      return
    }

    const formData = createFormData({
      park_id: selectedPark!.id,
      user_id: e.user_id,
      type: 2,
      date: e.date,
      format: e.format,
      sum: Math.abs(e.sum),
      comment: e.comment,
      role: e.role,
      working_rate: e.user?.working_rate ?? 0,
    })

    const { status, errors } = await logic.createEmployeeTrackingById(formData, rowIndex)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  const editReport = async (id: number, query: string) => {
    const { status, errors } = await logic.editEmployeeTrackingById(id, query)
    if (!status) context?.toastShowHandler({ status: false, errors: errors })
  }

  if (loading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }

  return (
    <Layout className='!p-0'>
      <section className='flex h-full w-full flex-col gap-8 overflow-auto bg-white px-4 py-5'>
        <header className='flex flex-row items-center gap-6'>
          <UiText className='text-lg font-bold'>Дололнительный учёт</UiText>
          <UiButton onClick={addRow}>
            <i className='pi pi-plus'></i>
          </UiButton>
        </header>

        <DataTable
          value={additionalTrackingReports}
          showGridlines
          size={'small'}
          filterLocale='ru'
          className={'ui-paginator-table h-fit bg-white text-base'}
          style={{ fontSize: '12px' }}
          removableSort
          emptyMessage='Данные не найдены'
          scrollable
          scrollHeight='flex'
          paginator
          rows={15}
          rowClassName={(e) => (e.format === 2 ? 'bg-bg-red' : '')}
        >
          <Column
            filterField='created_at'
            header='Дата'
            dataType='date'
            body={(e, options) => datePicketBodyTemplate(e, editReport, editAdditionalTrackingReport, options.rowIndex)}
          />
          <Column
            field='employee'
            header='Сотрудник'
            body={(e: ApiEmployeeTrackingReports, options) =>
              employeeBodyTemplate(e, employees, editReport, editAdditionalTrackingReport, options.rowIndex)
            }
          />
          <Column
            field='sum'
            header='Сумма'
            body={(e, options) => sumBodyTemplate(e, editReport, editAdditionalTrackingReport, options.rowIndex)}
          />
          <Column
            field='comment'
            header='Комментарий'
            body={(e, options) => commentBodyTemplate(e, editReport, editAdditionalTrackingReport, options.rowIndex)}
          />
          <Column
            field='delete'
            body={(e, options) =>
              buttonsTemplate(
                () => deleteReport(e, options.rowIndex),
                !e.id ? () => addReport(e, options.rowIndex) : undefined
              )
            }
          />
        </DataTable>
      </section>
    </Layout>
  )
})

const sumBodyTemplate = (
  report: ApiEmployeeTrackingReports,
  editCallback: (id: number, query: string) => Promise<void>,
  editLocalStateCallback: (report: ApiEmployeeTrackingReports, rowIndex?: number) => void,
  rowIndex: number
) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const onChangeHandler = (e: InputNumberChangeEvent) => {
    const format = e.value! < 0 ? 2 : 1
    editLocalStateCallback({ ...report, sum: e.value ?? undefined, format: format }, rowIndex)

    if (!report.id) {
      return
    }

    if (timer) {
      clearTimeout(timer)
    }

    const newTimer = setTimeout(() => {
      sendDataToBackend(e.value)
    }, 1500)

    setTimer(newTimer)
  }

  const sendDataToBackend = async (sum: number | null) => {
    if (sum === null) return
    const format = sum < 0 ? 2 : 1
    await editCallback(report.id!, `format=${format}&sum=${Math.abs(sum)}`)
  }

  return (
    <InputNumber
      value={report.sum}
      onChange={onChangeHandler}
      placeholder='Сумма'
      className='text-field h-[36px] w-[65px] rounded-[3px] border-green bg-white text-grey'
      inputClassName='border-green !text-left w-[65px]'
    ></InputNumber>
  )
}

const commentBodyTemplate = (
  report: ApiEmployeeTrackingReports,
  editCallback: (id: number, query: string) => Promise<void>,
  editLocalStateCallback: (report: ApiEmployeeTrackingReports, rowIndex?: number) => void,
  rowIndex: number
) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    editLocalStateCallback({ ...report, comment: e.target.value }, rowIndex)

    if (!report.id) return

    if (timer) {
      clearTimeout(timer)
    }

    const newTimer = setTimeout(() => {
      sendDataToBackend(e.target.value)
    }, 1500)

    setTimer(newTimer)
  }

  const sendDataToBackend = async (str: string) => {
    await editCallback(report.id!, `comment=${str}`)
  }

  return (
    <input
      className='text-field h-[36px] rounded-[3px] border-[1px] border-solid border-green bg-white px-[8px] py-[10px] text-grey focus:outline-none'
      placeholder='Комментарий'
      value={report.comment ?? undefined}
      onChange={onChangeHandler}
    ></input>
  )
}
