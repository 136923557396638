import { ApiBirthdayGame } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import { createFormData } from '@utils/createDataForm'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'primereact/dropdown'
import { FC, useContext } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { THoliday } from '../types'

interface IForm {
  id: string
  removeFormHandler: () => void
  initialValue?: ApiBirthdayGame
}

export const Form: FC<IForm> = observer(({ id, removeFormHandler, initialValue }) => {
  const {
    game: { gameZones, games, removeHolidayFromState },
  } = useStore()

  const methods = useForm<THoliday>({
    defaultValues: {
      name: initialValue?.name ?? '',
      game: initialValue ? games.find((game) => game.id === initialValue.game_id) : null,
      gamezone: initialValue ? gameZones.find((gameZone) => gameZone.id === initialValue.zone_id) : null,
      puzzle1: { file: null, path: initialValue?.images[0].path ?? '' },
      puzzle2: { file: null, path: initialValue?.images[1].path ?? '' },
      puzzle3: { file: null, path: initialValue?.images[2].path ?? '' },
      puzzle4: { file: null, path: initialValue?.images[3].path ?? '' },
    },
  })

  const logic = useLogic()
  const context = useContext(ToastContext)

  const onSumbit = async (data: THoliday) => {
    if (!initialValue) {
      if (!data.puzzle1.file || !data.puzzle2.file || !data.puzzle3.file || !data.puzzle4.file) {
        context?.toastShowHandler({ status: false, errors: 'Пожалуйста, загрузите 4 фотографии' })
        return
      }

      const formData = createFormData({
        name: data.name,
        zone_id: data.gamezone?.id,
        game_id: data.game?.id,
      })

      formData.append('images[0][image]', data.puzzle1.file)
      formData.append('images[0][type]', 'puzzle1')

      formData.append('images[1][image]', data.puzzle2.file)
      formData.append('images[1][type]', 'puzzle1_res')

      formData.append('images[2][image]', data.puzzle3.file)
      formData.append('images[2][type]', 'puzzle2')

      formData.append('images[3][image]', data.puzzle4.file)
      formData.append('images[3][type]', 'puzzle2_res')

      const { status } = await logic.createIntegrationBirthday(formData)
      if (!status) context?.toastShowHandler({ status: false })
    } else {
      const formData = new FormData()

      methods.getFieldState('name').isDirty && formData.append('name', data.name)
      methods.getFieldState('game').isDirty && formData.append('game_id', String(data.game?.id))
      methods.getFieldState('gamezone').isDirty && formData.append('zone_id', String(data.gamezone?.id))
      if (methods.getFieldState('puzzle1').isDirty && data.puzzle1.file) {
        formData.append('images[0][image]', data.puzzle1.file)
        formData.append('images[0][type]', 'puzzle1')
      }
      if (methods.getFieldState('puzzle2').isDirty && data.puzzle2.file) {
        formData.append('images[1][image]', data.puzzle2.file)
        formData.append('images[1][type]', 'puzzle1_res')
      }
      if (methods.getFieldState('puzzle3').isDirty && data.puzzle3.file) {
        formData.append('images[2][image]', data.puzzle3.file)
        formData.append('images[2][type]', 'puzzle2')
      }
      if (methods.getFieldState('puzzle4').isDirty && data.puzzle4.file) {
        formData.append('images[3][image]', data.puzzle4.file)
        formData.append('images[3][type]', 'puzzle2_res')
      }

      const { status, errors } = await logic.editIntegrationBirthday(initialValue!.id, formData)
      if (status) context?.toastShowHandler({ status: true })
      else context?.toastShowHandler({ status: false, errors: errors })
    }
  }

  const startQuestHandler = async () => {
    const { status, message } = await logic.startIntegrationBirthday(initialValue!.id)
    if (status) context?.toastShowHandler({ status: true, summary: message })
    else context?.toastShowHandler({ status: false, errors: message })
  }

  const deleteBirthdayHandler = async () => {
    const { status, errors } = await logic.deleteIntegrationBirthday(initialValue!.id)
    if (status) {
      context?.toastShowHandler({ status: true, summary: 'Удалено' })
      removeHolidayFromState(initialValue!.id)
      removeFormHandler()
    } else context?.toastShowHandler({ status: false, errors: errors })
  }

  const handleImageChange = (file: File | null, puzzleIndex: 1 | 2 | 3 | 4) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        methods.setValue(`puzzle${puzzleIndex}.path`, reader.result as string)
      }
      reader.readAsDataURL(file)
      methods.setValue(`puzzle${puzzleIndex}.file`, file)
    } else {
      methods.setValue(`puzzle${puzzleIndex}.path`, '')
      methods.setValue(`puzzle${puzzleIndex}.file`, null)
    }
  }

  return (
    <FormProvider {...methods}>
      <form className='not-last-form flex h-full flex-col' onSubmit={methods.handleSubmit(onSumbit)}>
        <div className='grid w-fit grid-cols-4 gap-x-4 gap-y-4 border-0 border-b-2 border-solid border-grey-200 py-5 sm:grid-cols-[182px_82px_82px_82px_82px_151px_36px_36px] sm:grid-rows-3'>
          <FormInput
            className='w-full'
            name={`name`}
            placeholder='Имя именника'
            required
            containerClassName='max-sm:col-start-1 max-sm:col-end-3'
          />
          <Controller
            control={methods.control}
            name={`game`}
            rules={{ required: 'Game is required.' }}
            render={({ field, fieldState }) => (
              <Dropdown
                value={field.value}
                optionLabel='name'
                className={`worker row-start-2 h-[36px] w-full shadow-none max-sm:col-start-1 max-sm:col-end-3 ${
                  fieldState.error ? 'rounded-sm border border-solid !border-red' : ''
                }`}
                panelClassName='worker-panel'
                placeholder='Выберите квест'
                color='green'
                options={games.filter((e) => e.type_id === 3)}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          <Controller
            control={methods.control}
            name={`gamezone`}
            rules={{ required: 'Gamezome is required.' }}
            render={({ field, fieldState }) => (
              <Dropdown
                value={field.value}
                optionLabel='name'
                className={`worker row-start-2 h-[36px] w-full shadow-none max-sm:col-start-3 max-sm:col-end-5 sm:row-start-3 ${
                  fieldState.error ? 'rounded-sm border border-solid !border-red' : ''
                }`}
                panelClassName='worker-panel'
                placeholder='Выберите зону'
                color='green'
                options={gameZones}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          {
            new Array(
              [1, 2, 3, 4].map((index) => {
                const puzzleIndex: 1 | 2 | 3 | 4 = index as 1 | 2 | 3 | 4

                return (
                  <Controller
                    key={puzzleIndex}
                    name={`puzzle${puzzleIndex}`}
                    control={methods.control}
                    render={({ field }) => (
                      <div className='row-start-3 flex flex-col items-center gap-2 max-sm:justify-end sm:row-start-1 sm:row-end-3'>
                        <UiText className='block text-sm font-bold'>{puzzleNameSwitcher(puzzleIndex)}</UiText>
                        {methods.watch(`puzzle${puzzleIndex}.path`) && (
                          <img src={field.value.path} alt='Preview' className='h-[50px] w-[50px]' />
                        )}
                        <label htmlFor={`fileInput${id + puzzleIndex}`}>
                          <UiText className='block cursor-pointer text-sm font-bold text-blue'>
                            {methods.watch(`puzzle${puzzleIndex}.path`) ? 'Заменить' : 'Добавить'}
                          </UiText>
                        </label>

                        <input
                          id={`fileInput${id + puzzleIndex}`}
                          type='file'
                          accept='image/*'
                          style={{ display: 'none' }} // Скрыть input
                          onChange={(e) => {
                            const file = e.target.files ? e.target.files[0] : null
                            field.onChange(file)
                            //@ts-ignore
                            handleImageChange(file, puzzleIndex)
                          }}
                        />
                      </div>
                    )}
                  />
                )
              })
            )
          }
          <UiButton className='row-start-4 max-sm:col-start-1 max-sm:col-end-5 sm:row-start-2'>
            {initialValue ? 'Изменить' : 'Сохранить'}
          </UiButton>
          <div className='col-start-3 col-end-5 row-start-1 flex gap-4 sm:col-start-6 sm:col-end-7'>
            {initialValue && (
              <UiButton type='button' onClick={startQuestHandler} className='w-full !bg-blue'>
                <i className='pi pi-play'></i>
              </UiButton>
            )}
            <UiButton
              color='red'
              type='button'
              onClick={initialValue ? deleteBirthdayHandler : removeFormHandler}
              className='w-full'
            >
              <i className='pi pi-trash max-sm:col-start-4'></i>
            </UiButton>
          </div>
        </div>
      </form>
    </FormProvider>
  )
})

const puzzleNameSwitcher = (index: number) => {
  switch (index) {
    case 1:
      return 'ПАЗЛ 1'
    case 2:
      return 'ПАЗЛ 1 ИТОГ'
    case 3:
      return 'ПАЗЛ 2'
    case 4:
      return 'ПАЗЛ 2 ИТОГ'
  }
}
